@tailwind base;
@tailwind components;
@tailwind utilities;

/* //making it easier to code */


/* *{
      border : 1px solid red;
} */
body,html {
      scroll-behavior: smooth;
      
}

body {
      font-family: "Montserrat", sans-serif;
}

@layer base {
      @supports (-webkit-text-stroke: 5px black) {
            .font-outline-5 {
                  -webkit-text-stroke: 5px black;
            }

            .font-outline-4 {
                  -webkit-text-stroke: 4px black;
            }

            .font-outline-3 {
                  -webkit-text-stroke: 3px black;
            }
      }

      @supports not (-webkit-text-stroke: 5px black) {
            .font-outline-5 {
                  text-shadow:
                        -5px -5px 0 black,
                        5px -5px 0 black,
                        -5px 5px 0 black,
                        5px 5px 0 black;
            }

            .font-outline-4 {
                  text-shadow:
                        -4px -4px 0 black,
                        4px -4px 0 black,
                        -4px 4px 0 black,
                        4px 4px 0 black;
            }

            .font-outline-3 {
                  text-shadow:
                        -3px -3px 0 black,
                        3px -3px 0 black,
                        -3px 3px 0 black,
                        3px 3px 0 black;
            }
      }


}