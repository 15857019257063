#becomea {
    text-shadow: 3px 2px 6px black;
}

#leo {
    text-shadow: 3px 2px 6px black;
}

#wdwd {
    text-shadow: 3px 2px 7px rgba(0, 0, 0, 0.658);
}