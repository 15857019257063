body {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.title {
    width: 100%;
    text-align: center;
}

.title-lite {
    display: inline-block;
    font-weight: 900;
    width: 100%;
    text-align: center;
    font-size: 5em;
    color: #eee;
}

.sidebar {
    float: left;
    width: 25%;
    height: 100vh;
    background-color: #f7d858;
}

.sidebar-content {
    position: fixed;
    width: 25%;
    height: 100%;
    -webkit-transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    -ms-transition: all 1s ease-in;
    -o-transition: all 1s ease-in;
    transition: all 1s ease-in;
}

.sidebar-list {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sidebar-list-menu {
    position: relative;
    list-style: none;
    margin-left: 100px;
    padding: 0;
}

.sidebar-list-menu::after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: -20px;
    width: 2px;
    height: 100%;
    background: #333;
    z-index: 9;
}

.sidebar-list-menu li a {
    position: relative;
    display: inline-block;
    line-height: 50px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}



.sidebar-list-menu li a.active {
    font-weight: 900;
}

.sidebar-list-menu-active-bar {
    position: absolute;
    width: 6px;
    height: 50px;
    background: #fff;
    top: 0;
    left: -22px;
    z-index: 999;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    transition-delay: 0;
}

.content {
    display: inline-block;
    width: 75%;
}

.content>.content-container {
    width: 100%;
    height: 100vh;
}

.content>.content-container:nth-child(n) {
    background: #fff;
}

.content>.content-container:nth-child(2n) {
    background: #f5f7f9;
}

/* .content--center {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
} */

@media (max-width: 768px) {

    .sidebar,
    .sidebar-content {
        width: 35%;
        display: none;
    }

    .content {
        width: 100%;
    }

    .sidebar-list-menu {
        margin-left: 15px;
    }

    .sidebar-list-menu::after {
        left: -10px;
    }

    .sidebar-list-menu-active-bar {
        left: -12px;
    }
}