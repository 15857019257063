#app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #fff;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
}

.swip {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    margin: 1rem;
    background-color: #fff;
}
