/* Flip card toggle */
.flip-card-toggle {
  background-color: transparent;
  /* width: 300px; */
  height: 355px;
  perspective: 1000px;
  cursor: pointer;
  user-select: none;
}

.flip-card-toggle-inner {
  position: relative;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  transition: transform 0.6s;
  transform-style: preserve-3d; 
}

.flip-card-toggle.flipped .flip-card-toggle-inner {
  transform: rotateY(180deg);
}

.flip-card-toggle-front,
.flip-card-toggle-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-toggle-back {
  transform: rotateY(180deg);
}
