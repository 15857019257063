.app {
    /* font-family:; */
    margin-top: 1rem;
    
    /* border-top: 1px solid #ccc; */
}

.item {
    
    border: 1px solid #000;
    margin: 20px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.itemBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0.5rem;
    font-size: x-large;
    font-weight: 500;
    text-align: left;
    color: #000;
    background-color: transparent;
    border: none;
}

.itemBtn:hover {
    background-color: transparent;
}

.itemBtnExpanded {
    background-color: transparent;
}

.itemContent {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    font-size: large;
    font-weight: 400;
    color: #000;

}

.itemPanel {
    padding: 1rem;
}

.chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
    transform: rotate(180deg);
}